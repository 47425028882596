import React, { useState, useEffect } from 'react';
import { Fade } from "react-awesome-reveal";
import "../styles/Carousel.scss";
import Whats from './Whats';
import img1 from "../images/carousel/1.jpg"
import img2 from "../images/carousel/2.jpg"
import img3 from "../images/carousel/3.jpg"
import img4 from "../images/carousel/4.jpg"
import img5 from "../images/carousel/5.jpg"

const Carousel = () => {

    const [anos, setAnos] = useState(0);
    const [reparaciones, setReparaciones] = useState(0);
    const [clientes, setClientes] = useState(0);

    let anosMax=10
    let reparacionesMax=180
    let clientesMax=200

    useEffect(() => {
      const intervalId1 = setInterval(() => {
        if (anos < anosMax) {
          setAnos(anos + 1);
        } else {
          clearInterval(intervalId1);
        }
      }, 10);

      return () => clearInterval(intervalId1);
    }, [anos]);

    useEffect(() => {
        const intervalId2 = setInterval(() => {
          if (reparaciones < reparacionesMax) {
            setReparaciones(reparaciones + 10);
          } else {
            clearInterval(intervalId2);
          }
        }, 5);
  
        return () => clearInterval(intervalId2);
      }, [reparaciones]);

      useEffect(() => {
        const intervalId3 = setInterval(() => {
          if (clientes < clientesMax) {
            setClientes(clientes + 5);
          } else {
            clearInterval(intervalId3);
          }
        }, 10);
  
        return () => clearInterval(intervalId3);
      }, [clientes]);

  return (
    <div className="mb-5 carouselContainer" id="inicio">
        <div className="carouselText">
            <Fade><h1 className="title1 mt-3"><b>Auto Gas LP</b></h1></Fade>
            <h2 className="mb-3 title2">"En AutoGas LP Cancún, convertimos tu vehículo a gas LP para que ahorres combustible y cuides el planeta con una alternativa económica y ecológica."</h2>
            <span className="firstText">En AutoGas LP Cancún, somos líderes en la conversión de vehículos de gasolina a gas LP, ofreciendo soluciones económicas, eficientes y ecológicas. Con años de experiencia en la industria automotriz, nos especializamos en la instalación de sistemas de gas LP en autos y camionetas, proporcionando a nuestros clientes un ahorro significativo en combustible y una alternativa más limpia para el medio ambiente.
            <br /> <br />
            Nuestro equipo de expertos realiza instalaciones de alta calidad, garantizando la seguridad y el rendimiento óptimo de cada vehículo convertido. Además, ofrecemos servicios completos de mantenimiento y monitoreo para asegurar que tu sistema de gas LP funcione perfectamente en todo momento.
            </span>
            <Fade><div className="container containerDatosMain mt-4 mb-3">
                <div className="containerDatos">
                    <span className="text-center contadorNum fw-bold" id="anosExp">{"+"+anos}</span><br/>
                    <span className="contadorNumText fw-bold">Años de experiencia</span>
                </div>
                <div className="containerDatos">
                    <span className="contadorNum fw-bold" id="reparaciones">{"+"+reparaciones}</span><br/>
                    <span className="contadorNumText fw-bold">Conversiones realizadas</span>
                </div>
                <div className="containerDatos">
                    <span className="contadorNum fw-bold" id="clientes">{"+"+clientes}</span><br/>
                    <span className="contadorNumText fw-bold">Clientes satisfechos</span>
                </div>
            </div></Fade>
            
            <Fade cascade="true" direction="left"><div className="mt-4 container containerBtn1">
               <Whats classStyle="col-sm-6 mb-2 fw-bold btn btnCarousel" phoneNumber="+529982146284" message="Hola, quiero más información acerca de sus servicios." text="CONTÁCTANOS" />
            </div></Fade>
        </div>
               
        <div className="carouselImages">
            <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner imgBox">
                    <div className="carousel-item active">
                        <center><img src={img1} className="d-block w-100 " alt=""/></center>
                    </div> 
                    <div className="carousel-item">
                        <center><img src={img2} className="d-block w-100 " alt=""/></center>
                    </div> 
                    <div className="carousel-item">
                        <center><img src={img3} className="d-block w-100 " alt=""/></center>
                    </div> 
                    <div className="carousel-item">
                        <center><img src={img4} className="d-block w-100 " alt=""/></center>
                    </div> 
                    <div className="carousel-item">
                        <center><img src={img5} className="d-block w-100 " alt=""/></center>
                    </div> 
                </div>
            </div> 
        </div>
    </div>
  );
};

export default Carousel;
