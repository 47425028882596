import React from "react";
import { Fade } from "react-awesome-reveal";
import { useMediaQuery } from "react-responsive";
import "../styles/PorqueElegirnos.scss";
import id1 from "../images/identificadores/1.svg";
import id2 from "../images/identificadores/2.svg";
import id3 from "../images/identificadores/3.svg";
import id4 from "../images/identificadores/4.svg";
import id5 from "../images/identificadores/5.svg";

const PorqueElegirnos = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  let velFade = 2000;

  const tarjetas = [
    {
        id: 1,
        imagen: id1,
        titulo: 'Instalación Profesional',
        texto: 'Utilizamos tecnología de punta y los mejores equipos para garantizar la seguridad y eficiencia de tu sistema de gas LP.',
    },
    {
        id: 2,
        imagen: id2,
        titulo: 'Garantía de Servicio',
        texto: ' Ofrecemos una garantía completa en todas nuestras instalaciones y servicios, para que conduzcas con confianza.',
    },
    {
        id: 3,
        imagen: id3,
        titulo: 'Conversión a Gas LP',
        texto: 'Transformamos tu vehículo para que opere con gas LP, ahorrando hasta un 50% en combustible.',
    },
    {
        id: 4,
        imagen: id4,
        titulo: 'Mantenimiento y Revisión',
        texto: 'Realizamos revisiones periódicas y ajustes necesarios para mantener tu sistema en óptimas condiciones.',
    },
    {
        id: 5,
        imagen: id5,
        titulo: 'Promociones y Financiamiento',
        texto: 'Contamos con promociones especiales y planes de financiamiento para hacer más accesible la conversión a gas LP.',
    },
  ];

  return (
    <section className="bg-body-tertiary text-center" id="porqueE">
      <center>
        <Fade>
          <h4 className="mt-5 fw-bold fs-3 firstText">
            ¿POR QUÉ ELEGIRNOS?
          </h4>
        </Fade>
      </center>
      <div className="containerCards">
            {tarjetas.map((tarjeta) => (
                <div key={tarjeta.id} className="containerCard text-center">
                    {isMobile && (
                        <Fade duration={velFade}>
                            <img className="imgCard" src={tarjeta.imagen} alt={tarjeta.titulo} />
                            <div className="containerCardText">
                                <p className="firstText fw-bold">{tarjeta.titulo}</p>
                                <p className="textIdentificador">{tarjeta.texto}</p>
                            </div>
                        </Fade>
                    )}
                    {!isMobile && (
                        <div>
                            <img className="imgCard" src={tarjeta.imagen} alt={tarjeta.titulo} />
                            <div className="containerCardText">
                                <p className="firstText fw-bold">{tarjeta.titulo}</p>
                                <p className="textIdentificador">{tarjeta.texto}</p>
                            </div>
                        </div>
                    )}
                </div>
            ))}
      </div>
      <br/><br/><br/>
    </section>
  );
};

export default PorqueElegirnos;
