import React from "react";
import { Fade } from "react-awesome-reveal";
import "../styles/Footer.scss";
import facebook from "../images/redes/Caralibro.svg"
import Instagram from "../images/redes/Instagram.svg"
import Linkedin from "../images/redes/Linkedin.svg"
import Tiktok from "../images/redes/Tik tok.svg"
import EsX from "../images/redes/X.svg"
import appStore from "../images/App.svg"
import logo from "../images/logo.svg"

const Footer = () => {

  return (
    <footer id="contacto">
        <div className="containerFooter">

            <div className="column1">
                <a href="./index.php"><img src={logo} alt="Logo Coorporativo Salcedo"/></a>
                <div className="containerTextColumn1">
                    <p>En AutoGas LP Cancún, nos enorgullece contar con una amplia base de clientes satisfechos que confían en nosotros para sus necesidades de conversión y mantenimiento de sistemas de gas LP. Nuestro compromiso es brindarte un servicio de excelencia, ayudándote a ahorrar dinero y a cuidar el medio ambiente.</p>
                </div>
            </div>

            <div className="column2">
                <div>
                    <p>NOSOTROS</p>
                    <div className="textColumn2">
                        <a href="#inicio"><span>Conócenos</span></a><br/>
                        <a href="#porqueE"><span>¿Porque elegirnos?</span></a><br/>
                    </div>
                </div><br/>
                <div>
                    <p>DATOS DE CONTACTO</p>
                    <div className="textColumn2">
                        <a href="tel:+529982146284"><span>998 214 6284</span></a>
                    </div>
                </div>
            </div>
            
            <div className="column3">
                <div>
                    <p>SERVICIOS</p>
                    <div className="textColumn3">
                        <a href="#servicio1"><span>Conversión a Gas LP</span></a><br/>
                        <a href="#servicio5"><span>Promociones y Financiamiento</span></a><br/><br/>
                        <span className="ubicacionTitle">UBICACIÓN</span><br/>
                        <a href="https://maps.app.goo.gl/v24z3mjjjozXozFB6" ><span id="ubicacion">Plus, Villas del Mar,77516 Cancún, Q.R.</span></a><br/>
                        
                    </div>
                </div>
            </div>

            <div className="column4">
                <div>
                    <p>APPS</p>
                    <div>
                        <span>Proximamente</span>
                    </div>
                    <div className="containerImgApp">
                        <img src={appStore} alt=""/>
                    </div>
                </div>
                
                <div className="containerRedes">
                    <Fade cascade="true" direction="left"><a href="https://www.facebook.com/profile.php?id=100075754882162"><img src={facebook} alt="Facebook"/></a></Fade>
                </div>
            </div>

        </div>
</footer>
  );
};

export default Footer;

