import React from "react";
import { Fade } from "react-awesome-reveal";
import Whats from './Whats';
import "../styles/Services.scss";
import img1 from "../images/services/1.png";
import img2 from "../images/services/2.png";
import img3 from "../images/services/3.png";
import img4 from "../images/services/4.png";
import img5 from "../images/services/5.png";

const Services = () => {
  const servicios = [
    {
        id: 1,
        imagen: img1,
        titulo: 'Conversión a Gas LP para tu Vehículo',
        texto: 'Ofrecemos el servicio de conversión a Gas LP, una alternativa económica y ecológica para reducir tus costos de combustible y cuidar el medio ambiente. Al optar por el Autogas, puedes ahorrar hasta un 50% en comparación con la gasolina, sin afectar el rendimiento de tu vehículo. Nuestro equipo especializado garantiza una instalación segura y de alta calidad, permitiéndote disfrutar de un combustible más limpio, con menor emisión de CO₂ y NOx. ¡Dale a tu auto la eficiencia y el ahorro que merece',
    },
    {
        id: 2,
        imagen: img2,
        titulo: '¿Cómo Funciona?',
        texto: '1. Enciende en gasolina y cuando alcanza la temperatura óptima de operación, conmuta automáticamente a Gas LP.<br />2. La computadora AEB emula los tiempos de inyección de la computadora a gasolina.<br />3. El Vaporizador “THOR” genera la cantidad de vapor requerida por la computadora.<br />4. Los Inyectores suministran la cantidad exacta por sus tiempos de inyección y variabilidad de apertura.<br />5. Se instala un módulo de corte de gasolina para prevenir el desgaste de la misma.',
    },
    {
        id: 3,
        imagen: img3,
        titulo: 'Instalación Profesional',
        texto: 'Utilizamos tecnología de punta y los mejores equipos para garantizar la seguridad y eficiencia de tu sistema de gas LP. Nuestros procesos cumplen con los más altos estándares de calidad, asegurando que cada conversión sea confiable, segura y adaptada a las necesidades de cada vehículo. Nuestro equipo de técnicos especializados realiza una instalación precisa y detallada para optimizar el rendimiento, reduciendo las emisiones contaminantes y el consumo de combustible.',
    },
    {
        id: 4,
        imagen: img4,
        titulo: 'Mantenimiento y Revisión de Sistemas de Gas LP',
        texto: 'Ofrecemos un servicio completo de mantenimiento y revisión para tu sistema de gas LP, asegurando su óptimo funcionamiento y máxima eficiencia. Realizamos revisiones periódicas y ajustes necesarios, identificando y corrigiendo posibles desgastes o desajustes que puedan afectar el rendimiento. Nuestro equipo de especialistas garantiza que cada componente esté en condiciones seguras y listas para un desempeño confiable, permitiéndote disfrutar de los beneficios de tu sistema de gas LP sin preocupaciones.',
    },
    {
        id: 5,
        imagen: img5,
        titulo: 'Garantía de Servicio, Promociones y Financiamiento',
        texto: 'Ofrecemos una garantía completa en todas nuestras instalaciones y servicios, brindándote la confianza de que tu vehículo estará en las mejores manos. Además, contamos con promociones especiales y planes de financiamiento flexibles, diseñados para hacer más accesible la conversión a gas LP. Así, podrás disfrutar de los beneficios de un sistema eficiente y económico sin comprometer tu presupuesto. Con nosotros, conducir con confianza nunca fue tan fácil.',
    }
  ];

  return (
    <div id="allServices">
    <h4 className="fw-bold title">NUESTRO SERVICIO</h4>
    {servicios.map((servicio) => (
      <section className="cursosContainer" id={"servicio"+servicio.id} key={servicio.id}>
          <div className="imgContainer text-center">
            <Fade  duration="1000"><img src={servicio.imagen} alt={servicio.titulo} /></Fade>
          </div>
          <div className="infoCursoContainer">
              <Fade duration="1000"><span className="titleService text-center"><strong>{servicio.titulo}</strong></span></Fade>
              <span className="textService" dangerouslySetInnerHTML={{ __html: servicio.texto }}></span>
              
              <div className="btnServiceContainer">
                <Whats classStyle="btn btnService" phoneNumber="+5219982146284" message="Hola, quiero más información acerca de sus servicios." text="¡Más información!"/>
              </div>
          </div>
      </section>
    ))}
    </div>
  );
};

export default Services;
